
import {computed, defineComponent, ref} from 'vue';
import {ElMenu} from 'element-plus';
import variables from '@/styles/variables.scss';
import Empty from '@/components/empty/Empty.vue';

export default defineComponent({
  name: 'NavSidebar',
  components: {Empty},
  props: {
    items: Array,
    activeKey: String,
    collapsed: Boolean,
    showActions: Boolean,
  },
  setup(props, {emit}) {
    const toggling = ref(false);
    const searchString = ref('');
    const navMenu = ref<typeof ElMenu | null>(null);
    const toggleTooltipValue = ref(false);

    const filteredItems = computed<NavItem[]>(() => {
      const items = props.items as NavItem[];
      if (!searchString.value) return items;
      return items.filter(d => d.title?.toLocaleLowerCase().includes(searchString.value.toLocaleLowerCase()));
    });

    const classes = computed(() => {
      const {collapsed} = props as NavSidebarProps;
      const cls = [];
      if (collapsed) cls.push('collapsed');
      // if (toggling.value) cls.push('toggling');
      return cls;
    });

    const onSelect = (index: string) => {
      emit('select', index);
    };

    const onStar = (index: string) => {
      emit('star', index);
    };

    const onDuplicate = (index: string) => {
      emit('duplicate', index);
    };

    const onRemove = (index: string) => {
      emit('remove', index);
    };

    const onToggle = () => {
      const {collapsed} = props as NavSidebarProps;
      emit('toggle', !collapsed);
      toggleTooltipValue.value = false;
    };

    const scroll = (id: string) => {
      const idx = filteredItems.value.findIndex(d => d.id === id);
      if (idx === -1) return;
      const {navSidebarItemHeight} = variables;
      const navSidebarItemHeightNumber = Number(navSidebarItemHeight.replace('px', ''));
      if (!navMenu.value) return;
      const $el = navMenu.value.$el as HTMLDivElement;
      $el.scrollTo({
        top: navSidebarItemHeightNumber * idx,
      });
    };

    return {
      toggling,
      searchString,
      navMenu,
      toggleTooltipValue,
      filteredItems,
      classes,
      onSelect,
      onStar,
      onDuplicate,
      onRemove,
      onToggle,
      scroll,
    };
  },
});
