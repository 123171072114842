
import {defineComponent} from 'vue';
import NavActionGroup from '@/components/nav/NavActionGroup.vue';
import NavActionButton from '@/components/nav/NavActionButton.vue';

export default defineComponent({
  name: 'NavActionGroupDetailCommon',
  components: {NavActionButton, NavActionGroup},
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'back',
  ],
  setup() {
    return {};
  },
});
