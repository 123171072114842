
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'NavTabs',
  props: {
    items: Array,
    activeKey: String,
  },
  setup(props, {emit}) {
    const onSelect = (index: string) => {
      emit('select', index);
    };

    return {
      onSelect,
    };
  },
});
