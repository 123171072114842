
import {computed, defineComponent, PropType} from 'vue';
import useDetail from '@/layouts/detail';
import NavSidebar from '@/components/nav/NavSidebar.vue';
import NavTabs from '@/components/nav/NavTabs.vue';
import NavActions from '@/components/nav/NavActions.vue';
import NavActionGroupDetailCommon from '@/components/nav/NavActionGroupDetailCommon.vue';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'DetailLayout',
  components: {
    NavActionGroupDetailCommon,
    NavSidebar,
    NavTabs,
    NavActions,
  },
  props: {
    storeNamespace: {
      type: String as PropType<ListStoreNamespace>,
      required: true,
    },
    noSidebar: {
      type: Boolean,
      default: false,
    },
    navItemNameKey: {
      type: String,
      default: 'name',
    }
  },
  setup(props: DetailLayoutProps, {emit}) {
    const ns = computed(() => props.storeNamespace);
    const store = useStore();
    const state = store.state[ns.value] as BaseStoreState;

    const computedNavItems = computed<NavItem[]>(() => state.allList.map((d: BaseModel) => {
      const {navItemNameKey} = props;
      return {
        id: d._id,
        title: d[navItemNameKey],
      } as NavItem;
    }));

    return {
      ...useDetail(ns.value),
      computedNavItems,
    };
  },
});
